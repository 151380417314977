
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const $ = require("jquery");

exports.onRouteUpdate = () => {
  $(document).ready(function(){
    $(".hamburger").click(function() {
      console.log("hello");
      $(this).toggleClass("active");
      $(".nav-menu").toggleClass("active");
    });
  });
}
